.dtx-login-modal-dialog {
  background: rgba($color: #fff, $alpha: 0.85);
  backdrop-filter: blur(6px);
  border-radius: 16px;
  max-width: calc(100vw - 2rem);

  .p-dialog-header {
    background: transparent;
  }
  .p-dialog-content {
    background: transparent;
  }
  .p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
  }
  .p-inputtext {
    background: rgba($color: #fff, $alpha: 0.2);
  }

  .p-steps .p-steps-item:before {
    border-top-color: var(--DtxPrimarySiteColour);
  }

  .p-skeleton {
    opacity: 0.75;
  }
}
